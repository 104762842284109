.CompactCard{
    display: flex;
    flex: 1;
    height: 7rem !important;
    border-radius: 0.7rem;
    color: #fff;
    position: relative;
    cursor: pointer;
    padding: 2rem;
}
.CompactCard:hover{
    box-shadow: none;
}
.radialBar{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}
.CircularProgressBar{
    width: 4rem !important;
    overflow: visible;
}
.CircularProgressBar-path{
    stroke: #fff !important;
    stroke-width: 12px !important;
    filter: drop-shadow(2px 4px 6px #fff);
}
.CircularProgressBar-trail{
    display: none;
}
.CircularProgressBar-text{
    fill: #fff !important;
}
.radialBar>span{
    font-size: 17px;
    font-weight: bold;
}
/*detail*/
.detail{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end; 
}
.detail>span:nth-child(2){
    font-size: 22px;
    font-weight: bold;
}
.detail>span:nth-child(3){
    font-size: 12px;
}
.ExpandedCard{
    position: absolute;
    width: 60%;
    height: 70%;
    z-index: 9;
    left: 13rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

}
.ExpandedCard > span:nth-of-type(1){
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px #fff;
}
.ExpandedCard > span:nth-of-type(2){
    color: #f3f3ff;
    font-size: 16px;
}
.chartContainer{
    width: 70%;
}

@media screen and (max-width:1200px) {
    .ExpandedCard{
        top: 2rem;
        height: 45vh;
        left: 6rem;
    }
}

@media screen and (max-width: 768px){
    .ExpandedCard{
        top: 8rem;
        height: 50%;
        left: 15px;
        width: 80%;
    }
}